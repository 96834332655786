<template>
	<div class="container">
		<bread-crums :nesting="['Dental Care', 'Oral Surgery']"></bread-crums>
		<intro button-text="Contact Us" button-link="/contact" title="Oral Surgery" :content="paragraphs"/>
		<div class="pgcontent">
			<div v-for="card in cards" :key="card.title" class="card">
				<h2>{{card.title}}</h2>
				<p>{{card.content}}</p>
			</div>
      <div v-for="section in sections" :key="section.title">
        <p>{{ section }}</p>
      </div>
			<div class="table">
				<price-table :procedure="Procedures['categories'][1]"></price-table>
			</div>
		</div>      
	</div>
</template>

<script>
import Intro from '../components/Intro.vue'
import PriceTable from '../components/PriceTable.vue'
import  Procedures  from "../assets/json/procedures.json";
import BreadCrums from '../components/BreadCrums.vue';
export default {
	name: 'GeneralDentisty',
	components: {
		Intro,
		PriceTable,
		BreadCrums
	},
	data(){
		return{
			Procedures,
			paragraphs: [
					"Unfortunately, sometimes teeth are not able to be saved, either being lost through natural causes, breakage or extensive decay or persistent infection. We know this can be a daunting procedure but we will take our time to ensure your experience is as pain-free and comfortable as possible. We will guide you through the procedure and will offer all advice to you throughout the journey. After the tooth has been lost, there may be, in some situations, an option to appropriately replace the gap. This may be with an implant, bridge or denture. We will ensure you are looked after and guarantee you are in the best hands."
			],
			cards:[
			],
      sections:[
        "The procedure involves a local anaesthestic to numb the area we are working on, and the tooth will be eased out of the jaw bone. Occasionally we may need to remove some of the bony support or section the tooth and roots, depending on the situation. In some instances, we may need to refer you to an Oral Surgeon Specialist, especially if you find yourself being particularly anxious and nervous at the thought of the extraction, as you may benefit from sedation treatment in addition to your extraction. We are happy to talk you through the options and process involved."
      ]
		}
	}
}
</script>

<style scoped>
	.pgcontent {
		background-color: #FFF;
		padding: 1.5rem;
	}
	h2{
		width: 95%;
		color: var(--primary)
	}
	@media screen and (min-width: 375px){
		.pgcontent{
			padding: 1.5rem 2.5rem;
		}
	}
	@media screen and (min-width: 768px){
		.pgcontent{
			padding: 1.5rem6rem;
		}	
	}
	@media screen and (min-width: 1024px) {
		
		.container{
			padding-top: 1rem;
		}
		.pgcontent{
			display: flex;
			flex-wrap: wrap;
			padding: 2rem var(--padding1);
			justify-content: center;
		}
		.card{
			max-width: 50%;			
		}
		p{
			width: 70%;
		}
		.table{
			width: 100%;
		}
		
	}
	@media screen and (min-width: 1440px) {		
		.pgcontent{
			padding: 2rem var(--padding2);
		}    
	}
	@media screen and (min-width: 1750px){
		.pgcontent{
			padding: 2rem 4rem;
		}    
	}
</style>